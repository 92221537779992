<script lang="ts" setup>
import { Dayjs } from "dayjs";

const { t } = useT();

const props = withDefaults(
	defineProps<{
		timeLeft?: string | number | Dayjs;
		bgColor?: string;
	}>(),
	{ bgColor: "rgba(var(--chattogram-rgb), 0.4)" }
);

const isGuest = useIsGuest();

const { durationLeft: promotionTime } = useCountdown({
	timestamp: (props.timeLeft as string) || "",
	formatToken: "DD[D ] HH[H ]mm[M ]",
	isOptimized: true
});
</script>

<template>
	<AText as="div" :modifiers="['uppercase', 'center']" class="timer">
		<template v-if="isGuest">{{ t("Register to Play") }}</template>
		<template v-else>
			{{ t("ends in:") }}&nbsp;<AText :modifiers="['medium']">{{ promotionTime }}</AText>
		</template>
	</AText>
</template>

<style scoped lang="scss">
.timer {
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 22px;
	border-radius: 12px 12px 0 0;
	background: v-bind(bgColor);
}
</style>
